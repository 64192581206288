import { template as template_df08a1d04fcf482c9e242f8c9409a7fa } from "@ember/template-compiler";
const FKText = template_df08a1d04fcf482c9e242f8c9409a7fa(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
