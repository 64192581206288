import { template as template_cf5a080760154bc6b6b0ae94887c1973 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cf5a080760154bc6b6b0ae94887c1973(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
