import { template as template_0097b701a6084fd1b936f5e2cc1cc560 } from "@ember/template-compiler";
const FKLabel = template_0097b701a6084fd1b936f5e2cc1cc560(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
